import {FormValidator} from "../../../tools/form/form-validator";
import {UploadFormModel} from "../../upload/upload-form-model";
import {createMeasurementFormValidationMap} from "../../upload/create-measurement-form-validation-map";

export class EFormValidator extends FormValidator<UploadFormModel> {

  constructor(uploadData: UploadFormModel) {
    super(uploadData, createMeasurementEFormValidationMap(uploadData));
  }
}

function createMeasurementEFormValidationMap(uploadData: UploadFormModel) {
  return new Map([
    ...createMeasurementFormValidationMap(uploadData)
      .entries()
  ]
    .filter((pair) => ["gender", "age", "height", "weight"].includes(pair[0])));
}
