import {AuthenticationService} from "./authentication.service";
import {AuthenticatedUserStore} from "./authenticated-user-store.service";
import {LocalStorageServiceFake} from "../../tools/local-storage.service.test-util";
import {AuthenticatedUser, UserRole} from "./authenticated-user";
import {UserCredentialsFormModel} from "../login/login-form-model";

export class AuthenticationServiceFake extends AuthenticationService {
  constructor() {
    super(new AuthenticatedUserStore(new LocalStorageServiceFake()));
  }
}

export function aValidAuthenticatedUser(
  role: UserRole = "NURSE",
  token = "anyToken",
  language = "fi",
  isChainDoctor = false,
  isNurseWithAllowedLocalMeasurementTypes = true,
  isNurseWithAllowedHomeMeasurementTypes = false,
  isNurseWithOperatorHospitals = false
): AuthenticatedUser {
  return {
    otpRequired: false,
    username: "anyUsername",
    role,
    token,
    language,
    isChainDoctor: isChainDoctor,
    isNurseWithAllowedLocalMeasurementTypes,
    isNurseWithAllowedHomeMeasurementTypes,
    isNurseWithOperatorHospitals
  };
}

export function otpRequiredAuthenticatedUser(role: UserRole = "NURSE", token = "", language = "fi"): AuthenticatedUser {
  return {
    otpRequired: true,
    username: "",
    role,
    token,
    language,
    isChainDoctor: false,
    isNurseWithAllowedLocalMeasurementTypes: false,
    isNurseWithAllowedHomeMeasurementTypes: false,
    isNurseWithOperatorHospitals: false
  };
}

export function aValidUserCredentials(username: string = "user", password: string = "pass"): UserCredentialsFormModel {
  return {
    username,
    password
  };
}
