import {NurseMeasurementType} from "../initiate-measurement/initiate-measurement-data";
import {ALL_MEASUREMENT_TYPES, MeasurementType} from "../measurement-models";

export function mapNurseMeasurementTypeToMeasurementType(
  nurseMeasurementType: NurseMeasurementType | undefined
): MeasurementType | undefined {

  if (nurseMeasurementType === undefined) {
    return undefined;
  }

  if (ALL_MEASUREMENT_TYPES.includes(nurseMeasurementType as MeasurementType)) {
    return nurseMeasurementType as MeasurementType;
  }

  switch (nurseMeasurementType) {
    case "HOME_SLEEP":
      return "SLEEP";
    case "HOME_BLOOD_PRESSURE":
      return "BLOOD_PRESSURE";
    case "HOME_ECG_ATRIAL_FIBRILLATION":
      return "ECG_ATRIAL_FIBRILLATION";
    case "HOME_SYMPTOM_HOLTER":
      return "SYMPTOM_HOLTER";
    case "HOME_HOLTER_24H":
    case "HOME_HOLTER_48H":
    case "HOME_HOLTER_7D":
      return "HOLTER_ECG";
    default:
      throw new Error(`Unsupported nurse measurement type: ${nurseMeasurementType}`);
  }
}
