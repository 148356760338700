import {HomeMeasurementStatus, MeasurementStatus} from "../found-measurement";
import {UserRole} from "../../authentication/authenticated-user";

export function shouldShowHomeStatusInStatusColumn(
  role: UserRole,
  status: MeasurementStatus,
  homeStatus: HomeMeasurementStatus | undefined
): boolean {
  return ["DATA_OFFICER", "NURSE"].includes(role)
    && status === "INITIATED"
    && homeStatus !== undefined;
}
