export interface Environment {
  readonly production: boolean;
  language: string;
  defaultLanguage: string;
  readonly useReleaseToggles: boolean;
  readonly eforms: boolean;
  readonly homeHolter: boolean;
  readonly recaptchaSiteKey: string;
  readonly version: number;
  readonly baseUrl: string;
  readonly defaultListIntervalDaysForNurse: number;
  readonly defaultListIntervalDays: number;
}

export const defaultEnvironment: Environment = {
  production: false,
  language: "en",
  defaultLanguage: "en",
  useReleaseToggles: false,
  eforms: false,
  homeHolter: false,
  recaptchaSiteKey: "",
  version: 0,
  baseUrl: "",
  defaultListIntervalDaysForNurse: 90,
  defaultListIntervalDays: 30,
};
