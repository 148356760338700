import {HomeMeasurementStatus} from "../../../list/found-measurement";


const statusesForWhichShown: HomeMeasurementStatus[] = [
  "WAITING_FOR_MAILING_INFO",
];

export function shouldShowPreMeasurementFields(homeStatus: HomeMeasurementStatus | undefined) {
  return homeStatus === undefined
    || statusesForWhichShown.includes(homeStatus);
}
