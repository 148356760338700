import {UserRole} from "../authentication/authenticated-user";

interface ColumnVisibilityParameters {
  role: UserRole;
  isOperator: boolean;
  isChainDoctor: boolean;
}

export function createColumnVisibility(
  {
    role,
    isOperator,
    isChainDoctor,
  }: ColumnVisibilityParameters
): ColumnVisibility {
  return {
    code: true,
    uniqueId: true,
    hospitalName: role === "DATA_OFFICER" || role === "DOCTOR" || role === "HMO" || (role === "NURSE" && !!isOperator),
    uploadTime: role === "NURSE" || role === "DATA_OFFICER" || role === "DOCTOR",
    nurse: role === "DATA_OFFICER",
    deliveryTime: role === "DATA_OFFICER" || role === "NURSE" || isChainDoctor,
    type: true,
    status: ["NURSE", "DATA_OFFICER", "DOCTOR"].includes(role),
    doctor: role === "DATA_OFFICER" || isChainDoctor,
    action: true,
    deviceSerialNumber: role === "DATA_OFFICER" || role === "HMO",
    initiateTime: ["NURSE", "DATA_OFFICER", "HMO"].includes(role),
    technician: role === "DATA_OFFICER",
    reportLanguage: role === "DATA_OFFICER" || role === "DOCTOR",
    mailingInfoSetAt: role === "HMO",
    homeStatus: role === "HMO",
    deviceMailedAt: role === "HMO",
    measuringCompletedAt: role === "HMO",
    eFormLanguage: role === "HMO",
  };
}

export interface ColumnVisibility {
  code: boolean;
  uniqueId: boolean;
  hospitalName: boolean;
  uploadTime: boolean;
  nurse: boolean;
  deliveryTime: boolean;
  type: boolean;
  status: boolean;
  doctor: boolean;
  action: boolean;
  deviceSerialNumber: boolean;
  initiateTime: boolean;
  technician: boolean;
  reportLanguage: boolean;
  mailingInfoSetAt: boolean;
  homeStatus: boolean;
  deviceMailedAt: boolean;
  measuringCompletedAt: boolean;
  eFormLanguage: boolean;
}
