import {Component, OnInit} from "@angular/core";
import {MeasurementIdProvider} from "../analize/measurement-id-provider";
import {ActivatedRoute} from "@angular/router";
import {RouteNavigator} from "../../tools/navigation/route-navigator.service";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {getTypeSelectionOptions} from "../measurement-type-select-options";
import {SelectionOptions} from "../../tools/form/form-field.component";
import {localizationKey, LocalizationStrings} from "../../i18n/i18n-model";
import {FormFieldConfig, PropertyChangeListener} from "../../tools/form/form-field-config";
import {isEmptyOrValidSSN, isExisting} from "../validations";
import {PdfDownloaderService} from "./pdf-downloader.service";
import {PdfFillerService} from "./pdf-filler.service";
import {ErrorCode, ErrorResponse, singleCodeFromResponse} from "../../tools/error-response";
import {flatMap} from "rxjs/operators";
import {NavigationRoute} from "../../tools/navigation/navigation-route";
import {PrintFormData, PrintFormService} from "./print-form.service";
import {InitiateMeasurementService} from "../initiate-measurement/initiate-measurement.service";
import {NurseMeasurementType} from "../initiate-measurement/initiate-measurement-data";
import {ALL_MEASUREMENT_TYPES, getMeasurementTypeLocalizationKey} from "../measurement-models";
import {getNurseMeasurementType} from "../get-nurse-measurement-type";
import {ReleaseToggleState} from "../../tools/release-toggles/release-toggle-state";
import {ClipboardService} from "ngx-clipboard";
import {selectHomeMeasurementInstructionsLocalizationKey} from "./select-home-measurement-instructions-localization-key";
import {I18nService} from "../../i18n/i18n.service";
import {replaceAnchors} from "../analize/ecg/generators/findings-generator/replace-anchors";
import {FormValidator} from "../../tools/form/form-validator";
import {FormConfigBase} from "../../tools/form/form-config-base";


@Component({
  selector: "app-print-form",
  templateUrl: "./print-form.component.html"
})
export class PrintFormComponent implements OnInit {
  printFormModel: PrintFormModel = {} as PrintFormModel;
  errorMessage?: string;
  private printFormValidator = new PrintFormValidator(this.printFormModel);

  formConfig!: PrintFormConfig;

  printFormData?: PrintFormData;

  feature373Released = ReleaseToggleState.getInstance().isReleased("COPY_HOME_MEASUREMENT_INSTRUCTIONS_373");
  feature395Released = ReleaseToggleState.getInstance().isReleased("DOWNLOAD_HOME_MEASUREMENT_INSTRUCTIONS_395");

  private measurementId?: number;

  private readonly propertyChangeListener: PropertyChangeListener<PrintFormModel>;

  constructor(
    private readonly printFormService: PrintFormService,
    private readonly measurementIdProvider: MeasurementIdProvider,
    private readonly route: ActivatedRoute,
    private readonly navigator: RouteNavigator,
    private readonly notifications: Notifications,
    private readonly pdfDownloaderService: PdfDownloaderService,
    private readonly pdfFillerService: PdfFillerService,
    private readonly initiateMeasurementService: InitiateMeasurementService,
    private readonly clipboardService: ClipboardService,
    private readonly i18nService: I18nService
  ) {

    const self = this;

    this.propertyChangeListener = {
      onPropertyChange(property: keyof PrintFormModel, newValue: any): void {
        self.printFormModel[property] = newValue;
        self.printFormValidator.updatePropertyValidation(property);

        self.errorMessage = self.printFormValidator.isFormValid() ? "" : "printOrderFormValidationErrorDefault";
      }
    };
  }

  ngOnInit(): void {
    this.measurementId = this.measurementIdProvider.getId(this.route);
    this.printFormService
      .getPrintFormData({
        measurementId: this.measurementId!
      })
      .subscribe(
        (printFormData) => {
          this.printFormData = printFormData;
          this.printFormModel = {
            type: getNurseMeasurementType(
              printFormData.type,
              printFormData.isHome,
              {
                specifiedHolterDuration: printFormData.specifiedHomeHolterDuration
              }
            ),
            code: printFormData.code,
            initiatedTime: printFormData.initiatedTime,
            uniqueId: printFormData.uniqueId,
            language: undefined,
            eFormCode: printFormData.eFormCode
          } as PrintFormModel;
          this.printFormValidator = new PrintFormValidator(this.printFormModel);
          this.formConfig = new PrintFormConfig(
            this.printFormModel,
            this.propertyChangeListener,
            (property) => this.printFormValidator.isPropertyValid(property),
            printFormData.availableLanguages
          );
        },
        () => {
          this.notifications.addNotification(NotificationType.ERROR, localizationKey("printOrderFormErrorGettingData"));
        }
      );
  }


  printFormButtonClick() {

    if (!this.isFormValid()) {
      return;
    }

    this.fillFormAndExportPdf(false);
  }

  private fillFormAndExportPdf(downloadFile: boolean) {
    this.pdfDownloaderService
      .getPdfTemplate({
        type: this.printFormData!.type,
        language: this.printFormModel.language!,
        isHome: this.printFormData!.isHome
      })
      .pipe(flatMap((template) => this.pdfFillerService.fillPdf<DynamicPdfTemplateData>(
        template.data,
        {
          patient_ssn: this.printFormModel.ssn,
          patient_name: this.printFormModel.name,
          patient_id: this.printFormModel.code,
          unique_id: this.printFormModel.uniqueId,
          "e-form_code": this.printFormModel.eFormCode!
        },
        downloadFile ? this.generateInstructionsFileName() : undefined
      )))
      .subscribe(
        () => {
          this.setEFormLanguage();
        },
        (errorResponse: ErrorResponse) => {
          const message = singleCodeFromResponse(errorResponse) === ErrorCode.PATIENT_FORM_TEMPLATE_NOT_FOUND
            ? localizationKey("printFormPatientTemplateNotFound") : localizationKey("printFormFetchError");

          this.notifications.addNotification(NotificationType.ERROR, message);
        });
  }

  private setEFormLanguage(onSuccess: () => void = () => {
  }) {
    this.initiateMeasurementService
      .setInitiatedMeasurementLanguage(this.measurementId!, this.printFormModel.language!)
      .subscribe(
        {
          next: () => {
            onSuccess();
            this.navigator.navigateTo(NavigationRoute.LIST);
          },
          error: () => this.notifications.addNotification(
            NotificationType.WARNING,
            localizationKey("initiatedMeasurementEFormLanguageNotSaved")
          )
        }
      );
  }

  copyHomeMeasurementInstructions() {

    if (!this.isFormValid()) {
      return;
    }
    this.setEFormLanguage(
      () => {
        const instructionsLocalizationKey = selectHomeMeasurementInstructionsLocalizationKey(this.printFormData!.type);
        const instructionsTemplate = this.i18nService.getLocalizedString(instructionsLocalizationKey, this.printFormModel.language);
        const instructions = replaceAnchors(instructionsTemplate, {eFormCode: this.printFormData!.eFormCode}) || "";
        this.clipboardService.copy(instructions);
        this.notifications.addNotification(NotificationType.OK, "homeMeasurementInstructionsCopySuccess");
      }
    );
  }

  private isFormValid() {

    this.printFormValidator.updateAllValidations();

    if (!this.printFormValidator.isFormValid()) {
      this.errorMessage = "printOrderFormValidationErrorDefault";
      return false;
    }

    return true;
  }

  getPrintButtonLabel(): keyof LocalizationStrings {
    return this.printFormData?.isHome && this.feature373Released
      ? "printReferralFormButtonLabel"
      : "printFormButtonLabel";
  }

  downloadHomeMeasurementInstructions() {
    if (!this.isFormValid()) {
      return;
    }

    this.fillFormAndExportPdf(true);
  }

  private generateInstructionsFileName(): string {
    const prefix = this.i18nService.getLocalizedString("homeMeasurementInstructionsFilePrefix", this.printFormModel.language);
    const measurementTypeLocalizationKey = getMeasurementTypeLocalizationKey(this.printFormModel.type);
    const measurementTypeString = this.i18nService.getLocalizedString(measurementTypeLocalizationKey, this.printFormModel.language);
    return `${prefix} ${measurementTypeString} ${this.printFormModel.code}.pdf`;
  }
}

class PrintFormValidator extends FormValidator<PrintFormModel> {

  constructor(hospitalInformation: PrintFormModel) {
    super(hospitalInformation, new Map<keyof PrintFormModel, (value: any) => boolean>()
      .set("ssn", (value: string) => isEmptyOrValidSSN(value))
      .set("language", (value: string) => isExisting(value)));
  }

}


class PrintFormConfig extends FormConfigBase<PrintFormModel> {

  private readonly languageToLocalizationMap = new Map<string, keyof LocalizationStrings>()
    .set("fi", "finnishLang")
    .set("sv", "swedishLang")
    .set("ru", "russianLang")
    .set("en", "englishLang");

  constructor(
    model: PrintFormModel,
    propertyChangeListener: PropertyChangeListener<PrintFormModel>,
    validationProvider: (property: keyof PrintFormModel) => (boolean | undefined),
    languageOptions: string[]
  ) {
    super(model, propertyChangeListener, validationProvider);

    this.language = this.createField({
      property: "language",
      label: "printFormLabelLanguage",
      type: "dropdown",
      required: true,
      invalidLabel: "printFormInvalidLabelLanguage",
      readOnly: false,
      selectionOptions: SelectionOptions.with<string>(
        ...languageOptions
          .map(value => ({value: value, text: localizationKey(this.languageToLocalizationMap.get(value)!)}))
      )
    });
  }

  readonly type = this.createField({
    property: "type",
    label: "printOrderFormType",
    type: "dropdown",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelType",
    readOnly: true,
    selectionOptions: getTypeSelectionOptions({
      types: [...ALL_MEASUREMENT_TYPES],
      homeTypes: [...ALL_MEASUREMENT_TYPES]
    })
  });

  readonly initiatedTime = this.createField({
    property: "initiatedTime",
    label: "printOrderFormLabelInitiatedTime",
    type: "localdate",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelInitiatedTime",
    readOnly: true
  });

  readonly name = this.createField({
    property: "name",
    label: "printOrderFormLabelName",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelName"
  });

  readonly ssn = this.createField({
    property: "ssn",
    label: "printOrderFormLabelSsn",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelSsn"
  });

  readonly code = this.createField({
    property: "code",
    label: "printOrderFormLabelCode",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelCode",
    readOnly: true
  });

  readonly uniqueId = this.createField({
    property: "uniqueId",
    label: "printOrderFormLabelUniqueId",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelUniqueId",
    readOnly: true
  });

  readonly language: FormFieldConfig<PrintFormModel>;
}

export interface PrintFormModel {
  type: NurseMeasurementType;
  initiatedTime: string;
  name?: string;
  ssn?: string;
  code: string;
  uniqueId: string;
  language?: string;
  eFormCode?: string;
}

interface DynamicPdfTemplateData {
  readonly patient_name: string | undefined;
  readonly patient_ssn: string | undefined;
  readonly patient_id: string;
  readonly unique_id: string;
  readonly "e-form_code": string;
}
