import {Component, Input} from '@angular/core';
import {RouteNavigator} from "../../../tools/navigation/route-navigator.service";
import {ListItem} from "../list-item";
import {emptyListItem} from "../list-item/list-item.component";
import {AuthenticatedUser} from "../../authentication/authenticated-user";
import {NavigationRoute} from "../../../tools/navigation/navigation-route";

@Component({
  selector: 'app-navigate-to-order-form',
  templateUrl: './navigate-to-print-order.component.html',
})
export class NavigateToPrintOrderComponent {

  @Input() item: ListItem = emptyListItem();
  @Input() user!: AuthenticatedUser;

  constructor(private readonly routeNavigator: RouteNavigator) {
  }

  canPrintOrder() {
    return this.item.foundMeasurement.isOrderedByCurrentHospital
      && this.item.foundMeasurement.status === "INITIATED"
      && this.user.role === "NURSE";
  }

  printOrder() {
    return this.routeNavigator.navigateToPath([
      NavigationRoute.PRINT_ORDER_FORM,
      this.item.foundMeasurement.id.toString()
    ]);
  }
}
