import {Injectable} from "@angular/core";
import {PDFDocument} from "pdf-lib";
import {map, mergeMap} from "rxjs/operators";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

@Injectable()
export class PdfFillerService {

  fillPdf<T>(
    pdf: string,
    data: T,
    fileName: string | undefined
  ) {
    return fromPromise(PDFDocument.load(pdf))
      .pipe(mergeMap(
        pdfDoc => {
          const form = pdfDoc.getForm();
          Object
            .entries(data as any)
            .forEach(([fieldName, value]) => fillFieldIfExists(
              form.getFields().map(field => field.getName()),
              fieldName,
              value,
              (fieldName, text) => form.getTextField(fieldName).setText(text))
            );
          return fromPromise(pdfDoc.save());
        }))
      .pipe(map(pdfData => {
          const file = new Blob([pdfData], {type: "application/pdf"});
          const fileURL = URL.createObjectURL(file);

          if (fileName === undefined) {
            window.open(fileURL);
          } else {
            Object
              .assign(
                document.createElement("a"),
                {
                  href: fileURL,
                  download: fileName
                }
              )
              .click();
          }
        })
      );
  }
}

export function fillFieldIfExists(
  fieldNames: string[],
  fieldName: string,
  value: any,
  setField: (fieldName: string, text: string) => void
) {
  if (!fieldNames.includes(fieldName) || value === undefined) {
    return;
  }
  setField(fieldName, String(value));
}
