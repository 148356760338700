import {Component, Input} from '@angular/core';
import {RouteNavigator} from "../../../tools/navigation/route-navigator.service";
import {ListItem} from "../list-item";
import {emptyListItem} from "../list-item/list-item.component";
import {AuthenticatedUser} from "../../authentication/authenticated-user";
import {NavigationRoute} from "../../../tools/navigation/navigation-route";

@Component({
  selector: 'app-navigate-to-print-form',
  templateUrl: './navigate-to-print-form.component.html'
})
export class NavigateToPrintFormComponent {

  @Input() item: ListItem = emptyListItem();
  @Input() user!: AuthenticatedUser;

  constructor(private readonly routeNavigator: RouteNavigator,) {
  }

  canPrintForm() {
    if (this.item.foundMeasurement.isOrderedByCurrentHospital) {
      return false;
    }
    return this.user.role === "NURSE"
      && this.item.foundMeasurement.status === "INITIATED";
  }

  printForm() {
    return this.routeNavigator.navigateToPath([
      NavigationRoute.PRINT_FORM,
      this.item.foundMeasurement.id.toString()
    ]);
  }


}
