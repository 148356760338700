import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from "@angular/core";
import {ListItem} from "../../list-item";
import {emptyListItem} from "../../list-item/list-item.component";
import {ReassignDoctorService} from "../reassign-doctor.service";
import {finalize} from "rxjs/operators";
import {Notifications, NotificationType} from "../../../../tools/notifications/notifications";
import {localizationKey} from "../../../../i18n/i18n-model";
import {ErrorCode, ErrorResponse} from "../../../../tools/error-response";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AuthenticationService} from "../../../authentication/authentication.service";

@Component({
  selector: "app-reassign-doctor-button",
  templateUrl: "./reassign-doctor-button.component.html",
})
export class ReassignDoctorButtonComponent {

  @Input()
  item: ListItem = emptyListItem();

  @Input()
  testId: string | undefined = undefined;

  @Output()
  measurementChanged = new EventEmitter<void>();

  modalRefReassignDoctorConfirm: BsModalRef | undefined;
  @ViewChild("templateConfirmReassignDoctor") templateConfirmReassignDoctor!: TemplateRef<any>;


  constructor(
    private readonly reassignDoctorService: ReassignDoctorService,
    private readonly notifications: Notifications,
    private readonly modalService: BsModalService,
    private readonly authenticationService: AuthenticationService
  ) {
  }



  reassign() {

    if (this.item.selectedDoctor === undefined) {
      return;
    }

    this.item.isReassignInProgress = true;

    this.reassignDoctorService
      .reassign(this.item.foundMeasurement.id, {doctorId: this.item.selectedDoctor!.id!})
      .pipe(finalize(() => this.item.isReassignInProgress = false))
      .subscribe((updatedMeasurement) => {
          this.item.foundMeasurement = updatedMeasurement;
          this.notifications.addNotification(NotificationType.OK, localizationKey("reassignMeasurementSucceeded"));
          this.modalRefReassignDoctorConfirm!.hide();
          this.measurementChanged.emit();
        },
        (error: ErrorResponse) => this.notifications.addNotification(
          NotificationType.ERROR,
          error.errorCodes.includes(ErrorCode.DOCTOR_DOES_NOT_HAVE_ACCESS_TO_EXTERNAL_ANALYSIS)
            ? localizationKey("assignFailedDoctorDoesNotHaveAccessToExternalAnalysis")
            : localizationKey("reassignMeasurementFailed")
        )
      );
  }

  onReassignClick() {
    this.modalRefReassignDoctorConfirm = this.modalService.show(this.templateConfirmReassignDoctor, {
      class: "modal-sm",
    });
  }
}
