import {Component, EventEmitter, Input, Output} from "@angular/core";
import {HomeMeasurementStatus} from "../../../list/found-measurement";
import {isFinishMeasuringButtonVisible} from "./is-finish-measuring-button-visible";
import {isFinishMeasuringButtonDisabled} from "./is-finish-measuring-button-disabled";
import {EFormFinishMeasuringService} from "./e-form-finish-measuring.service";
import {Notifications, NotificationType} from "../../../../tools/notifications/notifications";
import {localizationKey} from "../../../../i18n/i18n-model";
import {MeasurementType} from "../../../measurement-models";
import {ActionButtonState} from "../../../../tools/confirm-action-button/pre-confirm-action-button.component";

@Component({
  selector: "finish-measuring",
  templateUrl: "./finish-measuring.component.html",
})
export class FinishMeasuringComponent {

  @Input()
  homeStatus: HomeMeasurementStatus | undefined;
  @Input() startTime: string | undefined;
  @Input() eFormId!: string;
  @Input() measurementType!: MeasurementType;


  @Output()
  homeStatusChange = new EventEmitter<HomeMeasurementStatus>();

  actionState: ActionButtonState = "INITIAL";

  constructor(
    private readonly finishMeasuringService: EFormFinishMeasuringService,
    private readonly notifications: Notifications
  ) {
  }

  isFinishMeasuringButtonVisible() {
    return isFinishMeasuringButtonVisible(this.homeStatus);
  }

  isFinishMeasuringButtonDisabled() {
    return isFinishMeasuringButtonDisabled(this.homeStatus!, this.startTime, this.measurementType!);
  }

  finishMeasuring() {
    if (this.actionState === "INITIAL") {
      this.actionState = "CONFIRMATION";
      return;
    }

    this.actionState = "ACTION_IN_PROGRESS";
    this.finishMeasuringService.finishMeasuring(this.eFormId).subscribe({
      next: () => {
        this.homeStatus = "RETURNED_FROM_PATIENT";
        this.notifications.addOkNotificationWithoutTimeout(localizationKey("finishMeasuringSuccessMessage"));
        this.homeStatusChange.emit("RETURNED_FROM_PATIENT");
      },
      error: () => {
        this.notifications.addNotification(NotificationType.ERROR, localizationKey("finishMeasuringFailedErrorMessage"));
        this.actionState = "INITIAL";
      }
    });
  }
}

