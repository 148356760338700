import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {emptyFoundMeasurement, FoundMeasurement} from "../found-measurement";
import {AuthenticatedUser} from "../../authentication/authenticated-user";
import {LocalizationStrings} from "../../../i18n/i18n-model";
import {getMeasurementTypeDisplayLocalizationKey} from "../../measurement-models";
import {RouteNavigator} from "../../../tools/navigation/route-navigator.service";
import {languageDefinitions} from "../../../tools/languages";
import {Notifications} from "../../../tools/notifications/notifications";
import {ListItem} from "../list-item";
import {BsModalService} from "ngx-bootstrap/modal";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";
import {ColumnVisibility} from "../create-column-visibility";
import {AuthenticationService} from "../../authentication/authentication.service";
import {getStatusLocalizationKey} from "./get-status-localization-key";
import {getHomeStatusLocalizationKeyForInitiatedMeasurement} from "./get-home-status-localization-key";
import {shouldShowHomeStatusInStatusColumn} from "./should-show-home-status-in-status-column";

export function emptyListItem(): ListItem {
  return {
    foundMeasurement: emptyFoundMeasurement(),
    isCloseAnalysisInProgress: false,
    showReportUpload: false,
    isItemDetailsVisible: false,
    isRejectStudyInProgress: false,
    isDeleteMeasurementInProgress: false,
    isAssignTechnicianInProgress: false,
    isRestoreMeasurementInProgress: false,
    isReassignInProgress: false
  };
}

export type ReportDownloadAction = "PRIMARY" | "SECONDARY";


@Component({
  selector: "app-list-item",
  templateUrl: "./list-item.component.html"
})
export class ListItemComponent implements OnInit {

  feature319Released = ReleaseToggleState.getInstance().isReleased("HOME_STATUS_FOR_NURSE_AND_DO_319");

  @Input() columnVisibility: ColumnVisibility = {} as ColumnVisibility;
  @Input() item: ListItem = emptyListItem();

  @Output() measurementChanged = new EventEmitter<void>();


  user!: AuthenticatedUser;

  readonly languageFlags = Object.fromEntries(languageDefinitions
    .map(definition => [
      definition.value, definition.flag
    ]));

  constructor(
    private readonly routeNavigator: RouteNavigator,
    private readonly notifications: Notifications,
    private readonly modalService: BsModalService,
    private readonly authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.item.selectedDoctor = this.item.foundMeasurement.doctor;
    this.user = this.authenticationService.getCurrentAuthenticatedUser()!;
  }

  canViewDetails() {
    return this.user.role === "DATA_OFFICER";
  }

  toggleItemDetailsVisibility() {
    this.item.isItemDetailsVisible = !this.item.isItemDetailsVisible;
  }

  getItemTypeDisplayText(): keyof LocalizationStrings {
    return getMeasurementTypeDisplayLocalizationKey({
      type: this.item.foundMeasurement.type,
      isHome: this.item.foundMeasurement.isHome,
      actualDurationDays: this.item.foundMeasurement.durationDays,
      specifiedDurationDays: this.item.foundMeasurement.specifiedHomeHolterDuration
    });
  }

  canViewAsyncJobIndicator() {
    return this.user.role === "DATA_OFFICER";
  }

  isJobPending() {
    return this.item.foundMeasurement.asyncJobStatus === "PENDING";
  }

  isJobFailed() {
    return this.item.foundMeasurement.asyncJobStatus === "FAILED";
  }

  hasJobSucceeded() {
    return this.item.foundMeasurement.asyncJobStatus === "SUCCESS";
  }

  technicianName() {
    if (this.item.foundMeasurement.technician === undefined) {
      return "";
    }

    return `${this.item.foundMeasurement.technician.firstName} ${this.item.foundMeasurement.technician.lastName}`;
  }

  isDoctorSelectable(): boolean {
    return this.user.role === "DATA_OFFICER"
      && (this.item.foundMeasurement.status === "UPLOADED"
        || this.item.foundMeasurement.status === "PRE_ANALYSED"
        || (this.item.foundMeasurement.status === "READY_FOR_ANALYSIS"
          && this.item.foundMeasurement.doctor?.id !== undefined
          && this.item.foundMeasurement.doctor!.id! !== this.item.foundMeasurement.technician?.id
          && ReleaseToggleState.getInstance().isReleased("REASSIGN_DOCTOR_276"))
      );
  }

  shouldDisplayAssignedLabel(): boolean {
    return !!this.item.foundMeasurement.doctor && !this.isDoctorSelectable();
  }

  handleMeasurementChanged() {
    this.measurementChanged.emit();
  }

  onMeasurementEdited(measurement: FoundMeasurement) {
    this.item.foundMeasurement = measurement;
  }

  shouldMarkCurrentDoctorInDoctorSelect() {
    return this.item.foundMeasurement.status !== "READY_FOR_ANALYSIS";
  }

  getHomeStatusLocalizationKey(): keyof LocalizationStrings {
    return getHomeStatusLocalizationKeyForInitiatedMeasurement(this.item.foundMeasurement.homeStatus!, this.item.foundMeasurement.status);
  }

  getStatusLocalizationKey(): keyof LocalizationStrings | undefined {
    return getStatusLocalizationKey(
      {
        status: this.item.foundMeasurement.status,
        role: this.user.role,
        isOrdered: this.item.foundMeasurement.hasOperatorHospital,
        isHome: this.item.foundMeasurement.isHome
      }
    );
  }

  shouldShowHomeStatusInStatusColumn() {
    return shouldShowHomeStatusInStatusColumn(
      this.user!.role,
      this.item.foundMeasurement.status,
      this.item.foundMeasurement.homeStatus
    );
  }
}

