import {HomeMeasurementStatus} from "../../../list/found-measurement";

const statusesForWhichShown: HomeMeasurementStatus[] = [
  "MAILED_TO_PATIENT"
];

export function shouldShowPostMeasurementFields(homeStatus: HomeMeasurementStatus | undefined) {
  return homeStatus === undefined
    || statusesForWhichShown.includes(homeStatus);
}
