import {HomeMeasurementStatus, MeasurementStatus} from "../../found-measurement";
import {UserRole} from "../../../authentication/authenticated-user";


const allowedStatuses: HomeMeasurementStatus[] = [
  "MAILED_TO_PATIENT",
  "RETURNED_FROM_PATIENT"
];

export function shouldShowMailingInfoButton(
  role: UserRole,
  homeStatus: HomeMeasurementStatus | undefined,
  status: MeasurementStatus
): boolean {
  return homeStatus !== undefined
    && role === "HMO"
    && allowedStatuses.includes(homeStatus)
    && status !== "DONE";
}
