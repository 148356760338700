import {Injectable} from "@angular/core";
import * as pdfMake from "pdfmake/build/pdfmake";
import {TCreatedPdf} from "pdfmake/build/pdfmake";
import {Content, ContentColumns, TDocumentDefinitions} from "pdfmake/interfaces";
import {Observable} from "rxjs";
import {I18nService} from "../../../i18n/i18n.service";
import {convertToFullUrl, createFonts, createPatientValueGroupContent, formatLabel, getAssetUrl} from "../report";
import {BpReportModel} from "./bp-report-model";
import {getBloodPressureReportFileName} from "./get-blood-pressure-report-file-name";

@Injectable()
export class BloodPressureReportGenerator {
  readonly IMAGE_WIDTH = 485;
  readonly LABEL_WIDTH = 160;

  constructor(private readonly i18nService: I18nService) {
  }

  generateReport(reportModel: BpReportModel, imageBase64: string): Observable<File> {
    return new Observable((observer) =>
      this.makePdfReport(reportModel, imageBase64).getBlob((blob) => {
        const file = new File([blob], getBloodPressureReportFileName(reportModel));
        observer.next(file);
      }));
  }

  generateAndPreviewReport(reportModel: BpReportModel, imageBase64: string) {
    this.makePdfReport(reportModel, imageBase64).open();
  }

  private makePdfReport(reportModel: BpReportModel, imageBase64: string): TCreatedPdf {

    const fonts = createFonts();
    const documentDefinitions = this.createPdfDefinitions(reportModel, imageBase64);

    return pdfMake.createPdf(documentDefinitions, undefined, fonts);
  }

  private createPdfDefinitions(bpReportModel: BpReportModel, imageBase64: string): TDocumentDefinitions {
    return {
      pageMargins: [40, 90, 40, 80],
      header: this.createHeader(bpReportModel),
      footer: (currentPage, pageCount) => createFooter(bpReportModel, currentPage, pageCount),
      content: [
        {
          text: bpReportModel.findingsPageTitle,
          alignment: "center",
          bold: true,
          fontSize: 16,
          margin: [0, 0, 0, 10]
        },
        this.createChartImagePageContent(imageBase64),
        this.createFindingsPageContent(bpReportModel),
        this.createConclusionPageContent(bpReportModel),
        this.createDoctorSignature(bpReportModel),
        this.createPatientDataPageContent(bpReportModel),
      ],
      defaultStyle: {
        font: "Roboto",
        fontSize: 11,
        lineHeight: 1.1,
      },
      images: {
        logo: convertToFullUrl(bpReportModel.logoUrlPath),
        footerLogo: getAssetUrl("logo/report-footer-logo.png"),
      },
    };
  }

  createHeader(bpReportModel: BpReportModel): ContentColumns {
    return {
      columns: [
        { image: "logo", width: 180, margin: 25 },
        {
          text: [{
            text: formatLabel(bpReportModel.measurementCode.label),
            bold: true
          }, { text: bpReportModel.measurementCode.value }],
          alignment: "right",
          margin: 40,
        },
      ],
    };
  }

  createChartImagePageContent(imageBase64: string): Content {
    return [
      {
        image: imageBase64,
        width: this.IMAGE_WIDTH,
        margin: [0, 0, 0, 0],
        alignment: "left",
      }];
  }

  createFindingsPageContent(model: BpReportModel): Content {
    return [
      {
        text: `${model.startTime.label}: ${model.startTime.value}`,
        margin: [0,0,0,0],
      },
      {
        text: `${model.findingsSectionTitle}:`,
        margin: [0,5,0,0],
        fontSize: 14
      },
      {
        margin: [0, 0, 0, 10],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ["30%", "18%", "34%", "18%"],
          body: [
            [
              "",
              { text: this.i18nService.getLocalizedString("bpFindingsActiveTime"), bold: true },
              { text: this.i18nService.getLocalizedString("bpFindingsSleepTime"), bold: true },
              { text: this.i18nService.getLocalizedString("bpFindingsWholeDay"), bold: true },
            ],
            ...(
              model.findings
                ? [
                  [
                    { text: this.i18nService.getLocalizedString("bpFindingsAverageSystolicBP"), bold: true },
                    model.findings.avgSystolicBP[0],
                    model.findings.avgSystolicBP[1],
                    model.findings.avgSystolicBP[2],
                  ],
                  [
                    { text: this.i18nService.getLocalizedString("bpFindingsAverageDiastolicBP"), bold: true },
                    model.findings.avgDiastolicBP[0],
                    model.findings.avgDiastolicBP[1],
                    model.findings.avgDiastolicBP[2],
                  ],
                  [
                    { text: this.i18nService.getLocalizedString("bpFindingsAverageSystolicRange"), bold: true },
                    model.findings.avgSystolicRange[0],
                    model.findings.avgSystolicRange[1],
                    model.findings.avgSystolicRange[2],
                  ],
                  [
                    { text: this.i18nService.getLocalizedString("bpFindingsAverageDiastolicRange"), bold: true },
                    model.findings.avgDiastolicRange[0],
                    model.findings.avgDiastolicRange[1],
                    model.findings.avgDiastolicRange[2],
                  ],
                  [
                    { text: this.i18nService.getLocalizedString("bpFindingsSystolicBPload"), bold: true },
                    model.findings.systolicBPload[0],
                    model.findings.systolicBPload[1],
                    model.findings.systolicBPload[2],
                  ],
                  [
                    { text: this.i18nService.getLocalizedString("bpFindingsDiastolicBPload"), bold: true },
                    model.findings.diastolicBPload[0],
                    model.findings.diastolicBPload[1],
                    model.findings.diastolicBPload[2],
                  ],
                  [
                    { text: this.i18nService.getLocalizedString("bpFindingsAveragePuls"), bold: true },
                    model.findings.avgPuls[0],
                    model.findings.avgPuls[1],
                    model.findings.avgPuls[2],
                  ],
                ]
                : []
            ),
          ]
        },
      },
    ];
  }

  createConclusionPageContent(model: BpReportModel): Content {
    return [
      {
        text: formatLabel(model.conclusion.label),
        margin: [0,5,0,6],
        fontSize: 14,
      },
      {
        text: model.conclusion.value
      }
    ];
  }

  createDoctorSignature(model: BpReportModel): Content {
    return [
      { text: `${model.doctor.city} ${model.reportDate}`, margin: [0, 20, 0, 10] },
      { text: model.doctor.fullName, margin: [0, 0, 0, 0] },
      { text: model.doctor.title, margin: [0, 0, 0, 0] },
    ];
  }

  createPatientDataPageContent(model: BpReportModel): Content {
    return [
      { text: model.patientDataPageTitle, pageBreak: "before", fontSize: 14, bold: true, margin: [0, 0, 0, 10] },
      createPatientValueGroupContent([{ reportValue: model.patientData.reasonForStudy, labelPosition: "TOP" }]),
      createPatientValueGroupContent([
        { reportValue: model.patientData.gender, labelPosition: "LEFT" },
        { reportValue: model.patientData.age, labelPosition: "LEFT" },
        { reportValue: model.patientData.height, labelPosition: "LEFT" },
        { reportValue: model.patientData.weight, labelPosition: "LEFT" },
      ]),
      createPatientValueGroupContent([
        { reportValue: model.patientData.waistline, labelPosition: "LEFT" },
        { reportValue: model.patientData.cholesterol, labelPosition: "LEFT" },
        { reportValue: model.patientData.smoking, labelPosition: "LEFT" },
        { reportValue: model.patientData.diabetes, labelPosition: "LEFT" },
        { reportValue: model.patientData.coronaryArteryDisease, labelPosition: "LEFT" },
        { reportValue: model.patientData.coronaryInsufficiency, labelPosition: "LEFT" },
        { reportValue: model.patientData.cerebrovascularDisorders, labelPosition: "LEFT" },
        { reportValue: model.patientData.kidneyDisease, labelPosition: "LEFT" },
      ]),
      createPatientValueGroupContent([
        { reportValue: model.patientData.parentsHeartAttackUnder60, labelPosition: "LEFT" },
        { reportValue: model.patientData.parentsStrokeUnder75, labelPosition: "LEFT" },
        { reportValue: model.patientData.diagnosedHeartDisease, labelPosition: "TOP" }
      ]),
      createPatientValueGroupContent([
        { reportValue: model.patientData.sleepMeasurementNight, labelPosition: "LEFT" },
        { reportValue: model.patientData.goToBedTime, labelPosition: "LEFT" },
        { reportValue: model.patientData.wakeUpTime, labelPosition: "LEFT" }
      ]),
      createPatientValueGroupContent([{ reportValue: model.patientData.currentMedication, labelPosition: "TOP" }]),
      createPatientValueGroupContent([{ reportValue: model.patientData.patientFeedback, labelPosition: "TOP" }]),
      createPatientValueGroupContent([{ reportValue: model.patientData.diary, labelPosition: "TOP" }]),
      createPatientValueGroupContent([{ reportValue: model.uniqueId, labelPosition: "LEFT" }]),
    ];
  }
}


function createFooter(bpReportModel: BpReportModel, currentPage: number, pageCount: number): ContentColumns {
  return {
    margin: [40, 20, 40, 20],
    columns: [
      {image: "footerLogo", width: 80, margin: 0},
      {
        text: [{ text: formatLabel(bpReportModel.pageLabel) }, { text: `${currentPage}/${pageCount}`, bold: true }],
        alignment: "right",
      },
    ],
  };
}
