import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ActionLogComponent} from "./features/action-log/action-log.component";
import {BpAnalysisComponent} from "./features/analize/blood-pressure/bp-analysis.component";
import {EcgAnalysisComponent} from "./features/analize/ecg/ecg-analysis.component";
import {AuthGuard} from "./features/authentication/auth-guard.service";
import {UserRole} from "./features/authentication/authenticated-user";
import {HomeGuard} from "./features/authentication/home-guard.service";
import {BackgroundInformationComponent} from "./features/background-information/background-information.component";
import {ChangePasswordComponent} from "./features/change-password/change-password.component";
import {ListComponent} from "./features/list/list.component";
import {LoginComponent} from "./features/login/login.component";
import {NewUsersComponent} from "./features/new-users/new-users.component";
import {UploadComponent} from "./features/upload/upload.component";
import {UserListComponent} from "./features/user-list/user-list.component";
import {ErrorComponent} from "./tools/errors/error.component";
import {NavigationRoute} from "./tools/navigation/navigation-route";
import {ChangeLanguageComponent} from "./features/change-language/change-language.component";
import {InitiateMeasurementComponent} from "./features/initiate-measurement/initiate-measurement.component";
import {PrintOrderFormComponent} from "./features/print-order-form/print-order-form.component";
import {HospitalsComponent} from "./features/hospitals/hospitals.component";
import {EditHospitalComponent} from "./features/hospitals/edit-hospital/edit-hospital.component";
import {PrintFormComponent} from "./features/print-form/print-form.component";
import {MailDeviceComponent} from "./features/mail-device/mail-device.component";

/*
  having dynamic changes in "routes" object causes some issues
  during compilation so the trick is to declare "routes" empty and
  then push all needed routes to it.
*/
const routes: Routes = [];
const tempRoutes: Routes = [
  {
    path: NavigationRoute.LOGIN,
    component: LoginComponent,
  },
  {
    path: NavigationRoute.CHANGE_PASSWORD,
    component: ChangePasswordComponent,
  },
  {
    path: NavigationRoute.CHANGE_LANGUAGE,
    component: ChangeLanguageComponent,
  },
  {
    path: NavigationRoute.LIST,
    component: ListComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE", "DATA_OFFICER", "DOCTOR", "HMO"]),
  },
  {
    path: NavigationRoute.UPLOAD,
    component: UploadComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE"]),
  },
  {
    path: `${NavigationRoute.UPLOAD}/:id`,
    component: UploadComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE", "DATA_OFFICER", "HMO"]),
  },
  {
    path: `${NavigationRoute.ANALYSIS_ECG}/:id`,
    component: EcgAnalysisComponent,
    canActivate: [AuthGuard],
    data: routeData(["DOCTOR", "DATA_OFFICER"]),
  },
  {
    path: `${NavigationRoute.ANALYSIS_BLOOD_PRESSURE}/:id`,
    component: BpAnalysisComponent,
    canActivate: [AuthGuard],
    data: routeData(["DOCTOR", "DATA_OFFICER"]),
  },
  {
    path: `${NavigationRoute.ERRORS}/:errorId`,
    component: ErrorComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE", "DATA_OFFICER", "DOCTOR"]),
  },
  {
    path: `${NavigationRoute.ACTIONS}`,
    component: ActionLogComponent,
    canActivate: [AuthGuard],
    data: routeData(["DATA_OFFICER"]),
  },
  {
    path: `${NavigationRoute.INITIATE_MEASUREMENT}`,
    component: InitiateMeasurementComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE"]),
  },
  {
    path: `${NavigationRoute.PRINT_ORDER_FORM}/:id`,
    component: PrintOrderFormComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE"]),
  },
  {
    path: `${NavigationRoute.PRINT_FORM}/:id`,
    component: PrintFormComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE"]),
  },
  {
    path: `${NavigationRoute.ADD_USERS}`,
    component: NewUsersComponent,
    canActivate: [AuthGuard],
    data: routeData(["DATA_OFFICER"]),
  },
  {
    path: `${NavigationRoute.USER_LIST}`,
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: routeData(["DATA_OFFICER"]),
  },
  {
    path: `${NavigationRoute.ORDER_MEASUREMENT}`,
    component: InitiateMeasurementComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE"]),
  },
  {
    path: `${NavigationRoute.ORDER_HOME_MEASUREMENT}`,
    component: InitiateMeasurementComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE"]),
  },
  {
    path: `${NavigationRoute.BACKGROUND_INFORMATION}`,
    component: BackgroundInformationComponent,
    canActivate: [AuthGuard],
    data: routeData(["NURSE", "DATA_OFFICER"]),
  },
  {
    path: `${NavigationRoute.HOSPITALS}`,
    component: HospitalsComponent,
    canActivate: [AuthGuard],
    data: routeData(["DATA_OFFICER"]),
  },
  {
    path: `${NavigationRoute.EDIT_HOSPITAL}/:id`,
    component: EditHospitalComponent,
    canActivate: [AuthGuard],
    data: routeData(["DATA_OFFICER"]),
  },
  {
    path: `${NavigationRoute.MAIL_DEVICE}/:id`,
    component: MailDeviceComponent,
    canActivate: [AuthGuard],
    data: routeData(["HMO"]),
  },
  {
    path: NavigationRoute.HOME,
    children: [],
    canActivate: [HomeGuard],
  },
  {
    path: "**",
    redirectTo: `/${NavigationRoute.HOME}`,
  },
];
routes.push(...tempRoutes);

export interface RouteData {
  allowedRoles: UserRole[];
}

function routeData(roles: UserRole[]): RouteData {
  return {
    allowedRoles: roles,
  };
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
