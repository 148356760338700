import {Component, Input, OnInit} from "@angular/core";
import {ReportType} from "../../../measurement-models";
import {emptyListItem, ReportDownloadAction} from "../list-item.component";
import {ReportFilenameFactory} from "../../report/download/report-filename-factory.service";
import {ListItem} from "../../list-item";
import {AuthenticatedUser} from "../../../authentication/authenticated-user";
import {AuthenticationService} from "../../../authentication/authentication.service";

@Component({
  selector: "app-download-report",
  templateUrl: "./download-report.component.html",
})
export class DownloadReportComponent implements OnInit {

  @Input() item: ListItem = emptyListItem();

  private user!: AuthenticatedUser;

  constructor(
    private readonly reportFilenameFactory: ReportFilenameFactory,
    private readonly authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getCurrentAuthenticatedUser()!;
  }

  hasReport(): boolean {
    const role = this.user.role;
    const status = this.item.foundMeasurement.status;
    const isChainDoctor = this.user.isChainDoctor;

    switch (role) {
      case "NURSE":
        return status === "DONE";
      case "DATA_OFFICER":
        return status === "DONE" || status === "READY_FOR_POST_ANALYSIS";
      case "DOCTOR":
        return status === "DONE" && isChainDoctor;
      default:
        throw Error(`${role} should not have download report component!`);
    }
  }

  getDownloadUrl(reportDownloadAction: ReportDownloadAction): string {

    const fileName = this.reportFilenameFactory.createFilename(this.item.foundMeasurement, reportDownloadAction);

    const reportType: ReportType = "MEASUREMENT_REPORT";

    // eslint-disable-next-line max-len
    return `/api/measurements/${this.item.foundMeasurement.id}/report/${reportType}/download/${encodeURI(fileName)}?token=${this.user.token}`;
  }

  markReportDownloadedForNurse(): void {
    if (this.user.role === "NURSE") {
      this.item.foundMeasurement = {...this.item.foundMeasurement, nurseDownloadedReport: true};
    }
  }

  showNurseDownloadedReport(): boolean {
    if (this.item.foundMeasurement.nurseDownloadedReport) {
      return false;
    }

    return this.user.role === "NURSE"
      || this.user.role === "DATA_OFFICER";
  }
}
