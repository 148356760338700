import {Injectable} from "@angular/core";
import {ApiClient} from "../../tools/api-client";
import {Observable} from "rxjs";
import {MailingInfo} from "../e-forms/form/mailing-info/e-form-mailing-info.service";

export interface DeviceMailingConfirmation {
  readonly measurementId: number;
  readonly deviceSerialNumber: string;
}

@Injectable()
export class MailDeviceService {

  constructor(private apiClient: ApiClient) {
  }

  confirmDeviceMailing(confirmation: DeviceMailingConfirmation): Observable<void> {
    return this.apiClient.put(
      "/api/measurements/" + confirmation.measurementId + "/mailed-device",
      {deviceSerialNumber: confirmation.deviceSerialNumber}
    );
  }

  getPatientMailingInfo(measurementId: number): Observable<MailingInfo> {
    return this.apiClient.get(
      "/api/measurements/" + measurementId + "/mailing-info",
    );
  }

  getHomePatientFormContext(measurementId: number): Observable<HomePatientFormContext>{
    return this.apiClient.get(
      "/api/measurements/" + measurementId + "/home-patient-form-context",
    );
  }
}

export interface HomePatientFormContext {
  readonly pdfTemplate: string;
  readonly uniqueId: string;
  readonly eFormCode: string;
}
