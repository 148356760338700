import {
  ALL_HOME_MEASUREMENT_STATUSES,
  ALL_MEASUREMENT_STATUSES,
  MeasurementStatus,
  measurementStatusLocalizationKeys
} from "../found-measurement";
import {ALL_MEASUREMENT_TYPES, getMeasurementTypeLocalizationKey} from "../../measurement-models";
import {AuthenticatedUser, UserRole} from "../../authentication/authenticated-user";
import {SelectOption} from "../../../tools/select/select.component";
import {ListItem} from "../list-item";
import {LocalizationStrings} from "../../../i18n/i18n-model";
import {getHomeStatusLocalizationKey} from "../list-item/get-home-status-localization-key";

export type FilterColumn = "hospitalName" | "type" | "status" | "doctor" | "technician" | "homeStatus";

export type MeasurementStatusFilterOption = MeasurementStatus | "PROCESSING" | "DELETED" | undefined;

export const measurementStatusFilterOptionLocalizationKeys:
ReadonlyMap<MeasurementStatusFilterOption, ReadonlyMap<UserRole, keyof LocalizationStrings>> =
  new Map<MeasurementStatusFilterOption, ReadonlyMap<UserRole, keyof LocalizationStrings>>(
    measurementStatusLocalizationKeys.entries()
  )
    .set(
      "PROCESSING",
      new Map<UserRole, keyof LocalizationStrings>()
        .set("NURSE", "nurseMeasurementStatusProcessing")
    )
    .set(
      "DELETED",
      new Map<UserRole, keyof LocalizationStrings>()
        .set("DATA_OFFICER", "dataOfficerMeasurementStatusDeleted")
    )
;

export const noFilterOption = {
  value: undefined,
  text: "measurementListAllColumnFilterOption",
  localize: true,
} as SelectOption;

const NURSE_PROCESSING_STATUSES = new Set<MeasurementStatus>([
  "UPLOADED",
  "READY_FOR_ANALYSIS",
  "READY_FOR_POST_ANALYSIS",
  "PRE_ANALYSED"]);

export const NURSE_MEASUREMENT_STATUS_OPTIONS: MeasurementStatusFilterOption[] =
  ["PROCESSING", ...ALL_MEASUREMENT_STATUSES
    .filter(status => !NURSE_PROCESSING_STATUSES.has(status))];

export const DATA_OFFICER_MEASUREMENT_STATUS_OPTIONS: MeasurementStatusFilterOption[] =
  [...ALL_MEASUREMENT_STATUSES, "DELETED"];

export class ColumnFilter {

  constructor(private user: AuthenticatedUser) {
  }

  getOptions(items: ListItem[]): Map<FilterColumn, SelectOption[]> {
    return new Map<FilterColumn, SelectOption[]>([
      ["hospitalName", [noFilterOption, ...this.getHospitalOptions(items)]],
      ["type", [noFilterOption, ...this.getTypeOptions()]],
      ["status", [noFilterOption, ...this.getStatusOptions()]],
      ["doctor", [noFilterOption, ...this.getDoctorOptions(items)]],
      ["technician", [noFilterOption, ...this.getTechnicianOptions(items)]],
      ["homeStatus", [noFilterOption, ...this.getHomeStatusOptions()]]
    ]);
  }

  filter(items: ListItem[], filterMap: Map<FilterColumn, string>): ListItem[] {
    return items
      .filter(item => !filterMap.has("hospitalName") || item.foundMeasurement.hospitalName === filterMap.get("hospitalName"))
      .filter(item => !filterMap.has("type") || item.foundMeasurement.type === filterMap.get("type"))
      .filter(item => this.filterByStatus(item, filterMap))
      .filter(item => !filterMap.has("doctor") || item.foundMeasurement.doctor?.id.toString() === filterMap.get("doctor"))
      .filter(item => !filterMap.has("technician") || item.foundMeasurement.technician?.id.toString() === filterMap.get("technician"))
      .filter(item => this.filterByHomeStatus(item, filterMap));
  }

  private filterByStatus(item: ListItem, filterMap: Map<FilterColumn, string>) {
    const statusFilter = filterMap.get("status");
    return (item.foundMeasurement.deleted
        && this.user.role === "DATA_OFFICER"
        && statusFilter === "DELETED")
      || (!item.foundMeasurement.deleted
        && ((statusFilter === undefined
            && this.user.role !== "DATA_OFFICER")
          || (statusFilter === undefined
            && this.user.role === "DATA_OFFICER"
            && item.foundMeasurement.status !== "INITIATED")
          || (statusFilter === "PROCESSING"
            && NURSE_PROCESSING_STATUSES.has(item.foundMeasurement.status))
          || item.foundMeasurement.status === statusFilter));
  }

  private getHospitalOptions(items: ListItem[]) {
    return Array.from(new Set(items
      .map(item => item.foundMeasurement.hospitalName))
      .values())
      .map(item => ({
        value: item,
        text: item,
        localize: false,
      } as SelectOption))
      .sort((first, second) => first.text.localeCompare(second.text));
  }

  private getTypeOptions() {
    return ALL_MEASUREMENT_TYPES
      .map(item => ({
        value: item,
        text: getMeasurementTypeLocalizationKey(item),
        localize: true,
      } as SelectOption));
  }

  private getStatusOptions() {
    switch (this.user.role) {
      case "NURSE":
        return NURSE_MEASUREMENT_STATUS_OPTIONS
          .map(status => ({
            value: status,
            text: this.getStatusTranslation(status),
            localize: true,
          } as SelectOption));

      case "DOCTOR":
        return [
          {
            value: "READY_FOR_ANALYSIS",
            text: this.getStatusTranslation("READY_FOR_ANALYSIS"),
            localize: true,
          } as SelectOption,
          ...(this.user.isChainDoctor
            ? [{
              value: "DONE",
              text: this.getStatusTranslation("DONE"),
              localize: true,
            } as SelectOption]
            : []
          )
        ];

      default:
        return DATA_OFFICER_MEASUREMENT_STATUS_OPTIONS
          .map(status => ({
            value: status,
            text: this.getStatusTranslation(status),
            localize: true,
          } as SelectOption));
    }
  }

  private getStatusTranslation(status: MeasurementStatusFilterOption): keyof LocalizationStrings {
    return measurementStatusFilterOptionLocalizationKeys.get(status)!.get(this.user!.role)!;
  }


  private getDoctorOptions(items: ListItem[]) {
    return Array.from(new Map(items
      .filter(item => item.foundMeasurement.doctor)
      .map(item => [item.foundMeasurement.doctor?.id, item.foundMeasurement.doctor]))
      .values())
      .map(doctor => ({
        value: doctor?.id?.toString(),
        text: `${doctor?.firstName || ""} ${doctor?.lastName || ""}`,
        localize: false,
      } as SelectOption));
  }

  private getTechnicianOptions(items: ListItem[]) {
    return Array.from(new Map(items
      .filter(item => item.foundMeasurement.technician)
      .map(item => [item.foundMeasurement.technician?.id, item.foundMeasurement.technician]))
      .values())
      .map(technician => ({
        value: technician?.id?.toString(),
        text: `${technician?.firstName || ""} ${technician?.lastName || ""}`,
        localize: false,
      } as SelectOption));
  }

  private getHomeStatusOptions(): SelectOption[] {
    return ALL_HOME_MEASUREMENT_STATUSES
      .map(status => ({
        value: status,
        text: getHomeStatusLocalizationKey(status),
        localize: true,
      } as SelectOption));
  }

  private filterByHomeStatus(item: ListItem, filterMap: Map<FilterColumn, string>): boolean {
    const filteredHomeStatus = filterMap.get("homeStatus");

    if (filteredHomeStatus === undefined) {
      return true;
    }

    return item.foundMeasurement.homeStatus === filteredHomeStatus;
  }
}
