import {I18nService} from "../../i18n/i18n.service";
import {Analysis, Doctor, emptyDoctorFindings} from "../analize/analysis";
import {UserRole} from "../authentication/authenticated-user";
import {AuthenticationServiceFake, aValidAuthenticatedUser} from "../authentication/authentication.service.test-util";
import {MeasurementType} from "../measurement-models";
import {emptyPatientData} from "../upload/upload-model";
import {emptyFoundMeasurement, FoundMeasurement, MeasurementStatus} from "./found-measurement";
import {ListItemTextBuilder} from "./list-item-text-builder.service";
import {ListComponent} from "./list.component";
import {LocalStorageServiceFake} from "../../tools/local-storage.service.test-util";
import {ListServiceSpy} from "./list-service.spy";
import {ClockServiceStub} from "../../tools/time/clock-service.stub";
import {UserHospitalSettings} from "./user-hospital-settings.service";

export function emptyMeasurementForUserRole(role: UserRole): FoundMeasurement {
  switch (role) {
    case "DATA_OFFICER":
      return emptyDataOfficerMeasurement();
    case "NURSE":
      return emptyNurseMeasurement();
    case "DOCTOR":
      return emptyDoctorMeasurement();
    case "HMO":
      return emptyHmoMeasurement();
  }
}

export function emptyDataOfficerMeasurement(type: MeasurementType = "SLEEP"): FoundMeasurement {
  return {
    ...emptyFoundMeasurement(),
    id: 0,
    uniqueId: "anyUniqueId",
    patientData: emptyPatientData(),
    hospitalName: "anyHospital",
    code: "anyCode",
    uploadTime: "2020-01-02T01:43:01Z",
    type,
    status: "UPLOADED",
    bmi: undefined,
    essScore: undefined,
    doctor: undefined,
    deliveryTime: undefined,
    durationDays: undefined,
    nurse: {...emptyFoundMeasurement().nurse, email: "test@email.com"},
    startTime: undefined,
    nurseDownloadedReport: false,
    initiateTime: undefined,
    hasOperatorHospital: false,
    deleted: false,
    specifiedHomeHolterDuration: undefined,
    reportLanguage: "fi"
  };
}

export function emptyNurseMeasurement(type: MeasurementType = "SLEEP"): FoundMeasurement {
  return {
    ...emptyFoundMeasurement(),
    id: 0,
    uniqueId: "anyUniqueId",
    patientData: undefined,
    hospitalName: "anyHospital",
    code: "anyCode",
    uploadTime: "2020-01-02T01:43:01",
    type,
    status: "UPLOADED",
    bmi: undefined,
    essScore: undefined,
    doctor: undefined,
    deliveryTime: undefined,
    durationDays: undefined,
    nurse: {...emptyFoundMeasurement().nurse, email: "test@email.com"},
    startTime: undefined,
    nurseDownloadedReport: false,
    initiateTime: undefined,
    hasOperatorHospital: false,
    deleted: false,
  };
}

export function emptyOrderedMeasurement(type: MeasurementType = "SLEEP"): FoundMeasurement {
  return {
    ...emptyFoundMeasurement(),
    id: 0,
    uniqueId: "anyUniqueId",
    patientData: undefined,
    hospitalName: "anyHospital",
    code: "anyCode",
    uploadTime: undefined,
    type,
    status: "INITIATED",
    bmi: undefined,
    essScore: undefined,
    doctor: undefined,
    deliveryTime: undefined,
    durationDays: undefined,
    nurse: {...emptyFoundMeasurement().nurse, email: "test@email.com"},
    startTime: undefined,
    nurseDownloadedReport: false,
    initiateTime: undefined,
    hasOperatorHospital: true,
    deleted: false,
    isOrderedByCurrentHospital: true,
  };
}

export function emptyDoctorMeasurement(
  type: MeasurementType = "SYMPTOM_HOLTER",
  status: MeasurementStatus = "READY_FOR_ANALYSIS"
): FoundMeasurement {
  return {
    ...emptyFoundMeasurement(),
    id: 0,
    uniqueId: "anyUniqueId",
    patientData: undefined,
    hospitalName: "anyHospital",
    code: "anyCode",
    uploadTime: "2020-01-02T01:43:01Z",
    type,
    status,
    bmi: undefined,
    essScore: undefined,
    doctor: undefined,
    deliveryTime: undefined,
    durationDays: undefined,
    nurse: {...emptyFoundMeasurement().nurse, email: "test@email.com"},
    startTime: undefined,
    nurseDownloadedReport: false,
    initiateTime: undefined,
    hasOperatorHospital: false,
    deleted: false,
  };
}

export function emptyHmoMeasurement(
  type: MeasurementType = "SYMPTOM_HOLTER",
  status: MeasurementStatus = "READY_FOR_ANALYSIS"
): FoundMeasurement {
  return {
    ...emptyFoundMeasurement(),
    id: 0,
    uniqueId: "anyUniqueId",
    patientData: undefined,
    hospitalName: "anyHospital",
    code: "anyCode",
    uploadTime: undefined,
    type,
    status,
    bmi: undefined,
    essScore: undefined,
    doctor: undefined,
    deliveryTime: undefined,
    durationDays: undefined,
    nurse: {...emptyFoundMeasurement().nurse, email: "test@email.com"},
    startTime: undefined,
    nurseDownloadedReport: false,
    initiateTime: "2020-01-02T01:41:01Z",
    hasOperatorHospital: false,
    deleted: false,
    isHome: true,
    homeStatus: "WAITING_FOR_MAILING_INFO"
  };
}

export function anEcgAnalysis(): Analysis {
  return {
    id: 1,
    uniqueId: "any",
    code: "any",
    uploadTime: "2020-01-02T01:43:001Z",
    type: "SYMPTOM_HOLTER",
    status: "READY_FOR_ANALYSIS",
    patientData: emptyPatientData(),
    doctorFindings: emptyDoctorFindings(),
    doctor: aDoctor(),
    commentsCount: undefined,
    hospitalName: "Hospital",
    analysisGenerationConfig: {}
  };
}

export function aBpAnalysis(): Analysis {
  return {
    id: 1,
    uniqueId: "any",
    code: "any",
    uploadTime: "2020-01-02T01:43:001Z",
    type: "BLOOD_PRESSURE",
    status: "READY_FOR_ANALYSIS",
    patientData: emptyPatientData(),
    doctorFindings: emptyDoctorFindings(),
    doctor: aDoctor(),
    commentsCount: undefined,
    hospitalName: "Hospital",
    analysisGenerationConfig: {}
  };
}

export function aDoctor(): Doctor {
  return {
    title: "anyTitle",
    city: "anyCity",
    firstName: "anyFirst",
    lastName: "anyLast",
  };
}

export function closeableMeasurement(): FoundMeasurement {
  return {
    ...emptyNurseMeasurement(),
    status: "READY_FOR_POST_ANALYSIS",
    type: "SYMPTOM_HOLTER",
  };
}

export class ListItemTextGeneratorFake extends ListItemTextBuilder {
  constructor() {
    super({} as I18nService);
  }

  convertFunction: (item: FoundMeasurement) => string = () => "";

  build(item: FoundMeasurement): string {
    return this.convertFunction(item);
  }
}

export interface ListComponentFixture {
  userHospitalSettings: UserHospitalSettings;
  listComponent: ListComponent;
  listServiceSpy: ListServiceSpy;
  listLoadingProgressFlags: boolean[];
  authenticationServiceFake: AuthenticationServiceFake;
  clockServiceStub: ClockServiceStub;
  localStorageServiceFake: LocalStorageServiceFake;
}

export function initListComponentFixture(): ListComponentFixture {
  const listServiceSpy = new ListServiceSpy();
  const listLoadingProgressFlags: boolean[] = [];
  const authenticationServiceFake = new AuthenticationServiceFake();
  const clockServiceStub = new ClockServiceStub();
  const localStorageServiceFake = new LocalStorageServiceFake();
  const userHospitalSettings = new UserHospitalSettings();

  authenticationServiceFake.setCurrentAuthenticatedUser(aValidAuthenticatedUser("DATA_OFFICER"));

  const listComponent = new ListComponent(
    listServiceSpy,
    authenticationServiceFake,
    clockServiceStub,
    localStorageServiceFake,
    userHospitalSettings
  );


  listComponent.listLoadingInProgress$.subscribe((inProgress) => listLoadingProgressFlags.push(inProgress));
  listComponent.user = aValidAuthenticatedUser("DATA_OFFICER");

  return {
    listComponent,
    listServiceSpy,
    listLoadingProgressFlags,
    authenticationServiceFake,
    clockServiceStub,
    localStorageServiceFake,
    userHospitalSettings
  };
}
