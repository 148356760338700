import {Injectable} from "@angular/core";
import {NurseHospital} from "./nurseHospital";

@Injectable()
export class UserHospitalSettings {

  private nurseHospital?: NurseHospital;

  getHospital(): NurseHospital | undefined {
    return this.nurseHospital;
  }

  setHospital(hospital: NurseHospital | undefined) {
    this.nurseHospital = hospital;
  }
}
