<div class="row py-2" data-testid="measurement-item">
  <div class="col-sm" *ngIf="columnVisibility.code">
    <app-measurement-details-button
      *ngIf="canViewDetails()"
      class="mr-2"
      [detailsVisible]="item.isItemDetailsVisible"
      (buttonClickHandler)="toggleItemDetailsVisibility()"></app-measurement-details-button>
    <span data-testid="list-item-code">{{ item.foundMeasurement.code }}</span>
  </div>
  <div class="col-sm" data-testId="list-item-unique-id"
       *ngIf="columnVisibility.uniqueId">{{ item.foundMeasurement.uniqueId }}
  </div>
  <div class="col-sm" data-testId="list-item-hospital-name"
       *ngIf="columnVisibility.hospitalName">{{ item.foundMeasurement.hospitalName }}
  </div>

  <div class="col-sm" data-testId="list-item-initiate-time"
       *ngIf="columnVisibility.initiateTime">{{ item.foundMeasurement.initiateTime | appLocalDateTime }}
  </div>
  <div
    class="col-sm"
    data-testId="list-item-mailing-info-set-at"
    *ngIf="columnVisibility.mailingInfoSetAt"
  >
    {{ item.foundMeasurement.mailingInfoSetAt | appLocalDateTime }}
  </div>
  <div
    class="col-sm"
    data-testId="list-item-device-mailed-at"
    *ngIf="columnVisibility.deviceMailedAt"
  >
    {{ item.foundMeasurement.deviceMailedAt | appLocalDateTime }}
  </div>
  <div
    class="col-sm"
    data-testId="list-item-measuring-completed-at"
    *ngIf="columnVisibility.measuringCompletedAt"
  >
    {{ item.foundMeasurement.measuringCompletedAt | appLocalDateTime }}
  </div>
  <div
    class="col-sm"
    data-testId="list-item-home-status"
    *ngIf="columnVisibility.homeStatus"
  >
    {{ getHomeStatusLocalizationKey() | localize }}
  </div>

  <div class="col-sm" data-testId="list-item-upload-time"
       *ngIf="columnVisibility.uploadTime">{{ item.foundMeasurement.uploadTime | appLocalDateTime }}
  </div>
  <div
    class="col-sm"
    *ngIf="columnVisibility.nurse"
    data-testId="list-item-nurse"
  >
    {{ item.foundMeasurement.nurse.firstName && item.foundMeasurement.nurse.lastName ? item.foundMeasurement.nurse.firstName + ' ' + item.foundMeasurement.nurse.lastName : item.foundMeasurement.nurse.email }}
  </div>
  <div
    class="col-sm"
    *ngIf="columnVisibility.deliveryTime"
    data-testId="list-item-delivery-time"
  >{{ item.foundMeasurement.deliveryTime | appLocalDateTime }}
  </div>
  <div
    data-testId="list-item-type"
    class="col-sm" *ngIf="columnVisibility.type"
  >
    {{ getItemTypeDisplayText() | localize }}
  </div>
  <div
    data-testId="list-item-status"
    class="col-sm" *ngIf="columnVisibility.status"
  >
    <span data-testId="list-item-status-text">
        {{ getStatusLocalizationKey() | localize }}
    </span>
    <span *ngIf="canViewAsyncJobIndicator()">
          <i
            *ngIf="isJobPending()"
            data-testid="pending-job-indicator"
            class="text-warning fa fa-hourglass align-middle"
            [title]="'measurementsGridTitleJobPending' | localize"></i>
          <i
            *ngIf="isJobFailed()"
            data-testid="failed-job-indicator"
            class="text-danger fa fa-times-circle align-middle"
            [title]="'measurementsGridTitleJobFailed' | localize"></i>
          <i
            *ngIf="hasJobSucceeded()"
            data-testid="succeeded-job-indicator"
            class="text-success fa fa-check-circle align-middle"
            [title]="'measurementsGridTitleJobSucceeded' | localize"></i>
        </span>


    <ng-container *ngIf="feature319Released">
      <div
        class="small text-muted"
        data-testId="status-column-home-status-label"
        *ngIf="shouldShowHomeStatusInStatusColumn()"
      >
        ({{ getHomeStatusLocalizationKey() | localize }})
      </div>
    </ng-container>

  </div>

  <div
    class="col-sm" *ngIf="columnVisibility.technician"
    data-testId="list-item-technician"
  >
    {{ technicianName() }}
  </div>

  <div
    class="col-sm-2"
    data-testId="list-item-doctor"
    *ngIf="columnVisibility.doctor"
  >
    <doctor-selector
      *ngIf="isDoctorSelectable()"
      [currentlyAssignedDoctor]="item.foundMeasurement.doctor"
      [markCurrentDoctor]="shouldMarkCurrentDoctorInDoctorSelect()"
      (selectionChanged)="item.selectedDoctor = $event"
      [chainName]="item.foundMeasurement.chain?.name"
    ></doctor-selector>
    <span
      *ngIf="shouldDisplayAssignedLabel()"> {{ item.foundMeasurement.doctor?.firstName }} {{ item.foundMeasurement.doctor?.lastName }} </span>
  </div>

  <div
    class="col-sm"
    *ngIf="columnVisibility.deviceSerialNumber"
    data-testId="list-item-device-serial-number"
  >{{ item.foundMeasurement.deviceSerialNumber }}
  </div>

  <div class="col-sm" *ngIf="columnVisibility.reportLanguage" data-testId="list-item-report-language">
          <span *ngIf="item.foundMeasurement.reportLanguage"
          >{{ languageFlags[item.foundMeasurement.reportLanguage] }}</span>
  </div>

  <div class="col-sm" *ngIf="columnVisibility.eFormLanguage" data-testId="list-item-e-form-language">
          <span *ngIf="item.foundMeasurement.eFormLanguage"
          >{{ languageFlags[item.foundMeasurement.eFormLanguage] }}</span>
  </div>

  <div
    class="col-sm-2"
    *ngIf="columnVisibility.action"
    data-testId="list-item-action"
  >
    <app-list-item-action-buttons
      [item]="item"
      (measurementChanged)="handleMeasurementChanged()"
    >
    </app-list-item-action-buttons>

  </div>

  <div class="col-12" *ngIf="item.isItemDetailsVisible">
    <app-measurement-details
      [measurement]="item.foundMeasurement"
      (measurementChanged)="onMeasurementEdited($event)"
    ></app-measurement-details>
  </div>
</div>

